import HelixLogo from '../../assets/Logov5.svg';

const Header = ({navIsOpen, setNavIsOpen}) => (
  <header className="p-8 text-white bg-gradient-to-r from-gray-900 to-gray-950 lg:bg-none fixed w-full z-10 pointer-events-none">
    <div className="container mx-auto justify-between sm:justify-start flex xl:justify-between items-center gap-x-4">
      <a href='/'><img src={HelixLogo} className='h-16' alt='Helix Network Logo'/></a>
      <h1 className="text-2xl text-center md:text-2xl lg:text-4xl font-semibold text-red">
        Helix Network
      </h1>
      <div className='w-16'>
        <div className="lg:hidden z-10 top-0 right-0 absolute p-8">
          <button onClick={() => setNavIsOpen(!navIsOpen)} className={`pointer-events-auto flex items-center px-2 py-2 my-2 border rounded text-white border-white hover:text-white hover:border-white ${navIsOpen ? 'bg-gradient-to-b from-red-500 from-60% to-red-600' : 'hover:bg-gradient-to-b hover:from-red-500 hover:from-60% hover:to-red-600'}`}>
            <svg className="fill-current h-8 w-8" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
