import { createContext, useContext, useState } from "react";

const SeoContext = createContext();

export const useSeo = () => {
  const context = useContext(SeoContext)
  if (!context) {
    throw new Error('useSeo must be used within SeoProvider')
  }
  return context;
}

const SeoProvider = ({ children }) => {
  const [seoTitle, setSeoTitle] = useState(null);
  const [metaDescription, setMetaDescription] = useState(null);

  const setSeoData = (title, description) => {
    setSeoTitle(title);
    setMetaDescription(description);
  }

  return (
    <SeoContext.Provider value={{ setSeoData, seoTitle, metaDescription }}>
      {children}
    </SeoContext.Provider>
  )
}

export default SeoProvider;