const Footer = () => {
  return (
    <footer className="text-white bg-gray-700 py-16 text-center">
      <div className="container flex flex-wrap justify-between mx-auto px-4">
        <div className="w-full md:w-1/2 mb-4 md:text-left">
          <p>Helix Network LTD is a registered company in England and Wales.</p>
          <p>Company number: 12509841</p>
          <p>Registered office address: 2nd Floor, College House,</p>
          <p> 17 King Edwards Road, Ruislip,</p>
          <p>United Kingdom, HA4 7AE</p>
        </div>
        <div className="w-full md:w-1/2 mb-4 md:text-right">
          <p><a href="/privacy-policy" className="font-bold text-red-300 hover:text-red-400 hover:underline">Privacy Policy</a></p>
        </div>
      </div>
      <p className="text-center mt-8">&copy; 2024 Helix Network Ltd. All rights reserved.</p>
    </footer>
  );
};

export default Footer;