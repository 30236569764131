import { Header, NavBar, Footer } from './components'
import { useState } from 'react';
import ReactDOM from 'react-dom';
import FavIcon from '../assets/favicon.ico';
import "../style.css";
import { useSeo } from '../utils/providers/seo_provider';

const HeadData = ({description, title}) => {
  return ReactDOM.createPortal(
    <>
      <link rel="icon" href={FavIcon} />
      <link rel="apple-touch-icon" href={FavIcon} />
      <meta name="description" content={description} />
      <title>{title}</title>
    </>,
    document.head
  );
};

const MainLayout = ({children}) => {
  const [navIsOpen, setNavIsOpen] = useState(false)
  const { seoTitle, metaDescription } = useSeo();
  return (
    <div className="font-sans bg-gradient-to-r from-gray-900 to-gray-950 min-h-screen">
      <HeadData 
        description={metaDescription}
        title={seoTitle}
      />
      <NavBar 
        navIsOpen={navIsOpen}
      />
      <Header 
        navIsOpen={navIsOpen}
        setNavIsOpen={setNavIsOpen}
      />
      {children}
      <Footer />
    </div>
  )
}

export default MainLayout