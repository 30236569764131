import React, {Suspense} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from "./layouts/main_layout";
import ToastProvider from './utils/providers/toast_provider';
import SeoProvider from './utils/providers/seo_provider';

const HomePage = React.lazy(() => import('./home').then(module => ({default: module.Home})));
const PrivacyPolicyPage = React.lazy(() => import('./privacy_policy').then(module => ({default: module.PrivacyPolicy})));
const WorkApp = React.lazy(() => import('./work').then(module => ({default: module.WorkApp})));

const App = () => {
  return (
    <Router>
      <SeoProvider>
        <MainLayout>
          <ToastProvider >
            <Suspense fallback={<div className='h-screen'>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              {//<Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
              
              }
              <Route path="/work/*" element={<WorkApp />}/>
            </Routes>
            </Suspense>
          </ToastProvider>
        </MainLayout>
      </SeoProvider>
    </Router>
  );
}

export default App;