import { Link } from "react-router-dom";

const NavBar = ({ navIsOpen }) => (
  <nav className='w-full top-0 left-0 fixed pointer-events-none z-10 lg:bg-gradient-to-r lg:from-gray-900 lg:to-gray-950'>
    <div className={`${navIsOpen ? 'translate-y-28' : '-translate-y-full'} lg:translate-y-0 transition duration-500 ease-in-out lg:z-10 md:flex text-white pt-6 pb-4 w-full rounded-b-2xl drop-shadow-2xl shadow-2xl bg-gradient-to-r from-gray-900 to-gray-950 lg:bg-none lg:rounded-none lg:drop-shadow-none lg:shadow-none lg:container lg:mx-auto`}>
      <ul className="justify-end flex flex-col pointer-events-auto lg:flex-row lg:space-y-0 lg:space-x-6 text-2xl lg:text-lg w-full h-24 items-center py-4">
        <li className='w-full lg:w-auto text-center hover:bg-gray-700 py-2 px-2 rounded-lg'>
          <Link to="/#features" className="hover:text-gray-300">Features</Link>
        </li>
        <li className='w-full lg:w-auto text-center hover:bg-gray-700 py-2 px-2 rounded-lg'>
          <Link to='/work' className="hover:text-gray-300">Work</Link>
        </li>
        <li className='w-full lg:w-auto text-center hover:bg-gray-700 py-2 px-2 rounded-lg'>
          <Link to="/#contact" className="hover:text-gray-300">Contact</Link>
        </li>
      </ul>
    </div>
  </nav>
);

export default NavBar;